export const overlayColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '名称',
      align: 'center',
      width: '200px',
      dataIndex: 'name',
    },
    {
      title: '类型',
      align: 'center',
      width: '100px',
      dataIndex: 'type',
      scopedSlots: { customRender: 'typeSlot' },
    },
    {
      title: '所属模块',
      align: 'center',
      width: '160px',
      dataIndex: 'systemModule',
    },
    {
      title: '实例ID',
      align: 'center',
      width: '100px',
      dataIndex: 'instanceId',
    },
    {
      title: '位置',
      align: 'left',
      ellipsis: true,
      dataIndex: 'position',
    },
    /* {
      title: '属性',
      align: 'left',
      width: '300px',
      dataIndex: 'options'
    },*/
    {
      title: '备注',
      align: 'center',
      width: '180px',
      dataIndex: 'memo',
      customRender: (text, record) => {
        const time = `${record.memo ? record.memo : '-'}` //es6写法
        return time
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '140px',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const bindMapColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '覆盖物名称',
      align: 'center',
      width: '200px',
      dataIndex: 'mapOverlay.name',
    },
    {
      title: '覆盖物类型',
      align: 'center',
      width: '100px',
      dataIndex: 'mapOverlay.type',
      scopedSlots: { customRender: 'typeSlot' },
    },
    {
      title: '操作',
      align: 'center',
      width: '140px',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const allOverlayapColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '覆盖物名称',
      align: 'center',
      width: '200px',
      dataIndex: 'name',
    },
    {
      title: '覆盖物类型',
      align: 'center',
      width: '100px',
      dataIndex: 'type',
      scopedSlots: { customRender: 'typeSlot' },
    },
    {
      title: '操作',
      align: 'center',
      width: '140px',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const overlayFormVO = () => {
  return {
    id: '',
    name: '',
    type: '',
    systemModule: '',
    instanceId: null,
    memo: '',
    position: '',
    options: '',
  }
}

export const overlayTypeList = () => {
  return [
    { value: 'marker', label: '点' },
    { value: 'polyline', label: '线' },
    { value: 'polygon', label: '面' },
  ]
}
