<template>
  <div>
    <div>
      <a-form layout="inline">
        <a-form-item label="覆盖物名称" style="margin-right: 35px;">
          <a-input
            v-model="bindSearchParams.name"
            allowClear
            @change="bindSearch"
            placeholder="请输入名称"
            style="width: 150px;"
          />
        </a-form-item>
        <a-form-item label="覆盖物类型" style="margin-right: 35px;">
          <a-select
            allowClear
            showSearch
            style="width: 150px;"
            v-model="bindSearchParams.type"
            placeholder="请选择类型"
            @change="bindSearch"
          >
            <a-select-option
              v-for="(item, index) in OverlayTypeList"
              :key="index"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="bindSearch" icon="search">查询</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin: 20px 0 0 0;">
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        :pagination="bindPagination"
        @change="bindTableChange"
        :columns="bindColumns"
        :dataSource="bindTableData"
      >
        <template slot="title"> 已绑定覆盖物</template>
        <template slot="_index" slot-scope="text, record, index">
          {{ bindSearchParams.number * bindSearchParams.size + index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            type="danger"
            @click="handleBindDelete(record.id)"
            shape="circle"
            icon="minus"
            size="small"
          ></a-button>
        </template>
        <template slot="typeSlot" slot-scope="text, record">
          <a-icon type="environment" v-if="record.mapOverlay.type === 'marker'" />
          <a-icon type="dash" v-if="record.mapOverlay.type === 'polyline'" />
          <a-icon type="radius-setting" v-if="record.mapOverlay.type === 'polygon'" />
        </template>
      </a-table>
    </div>
    <div style="margin: 20px 0 0 0;">
      <a-form layout="inline">
        <a-form-item label="覆盖物名称" style="margin-right: 35px;">
          <a-input
            v-model="searchParams.name"
            allowClear
            @change="overlaySearch"
            placeholder="请输入名称"
            style="width: 150px;"
          />
        </a-form-item>
        <a-form-item label="覆盖物类型" style="margin-right: 35px;">
          <a-select
            allowClear
            showSearch
            style="width: 150px;"
            v-model="searchParams.type"
            placeholder="请选择类型"
            @change="overlaySearch"
          >
            <a-select-option
              v-for="(item, index) in OverlayTypeList"
              :key="index"
              :value="item.value"
              :label="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="overlaySearch" icon="search">查询</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin: 20px 0 0 0;">
      <a-table
        :rowSelection="rowSelection"
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="overlayPagination"
        :columns="overlayColumns"
        :dataSource="overlayTableData"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="title"
          >全部覆盖物
          <a-button type="primary" @click="handleMultipleAdd" size="small" style="float: right;">批量綁定</a-button>
        </template>
        <template slot="typeSlot" slot-scope="text, record">
          <a-icon type="environment" v-if="record.type === 'marker'" />
          <a-icon type="dash" v-if="record.type === 'polyline'" />
          <a-icon type="radius-setting" v-if="record.type === 'polygon'" />
        </template>
        <template slot="action" slot-scope="record">
          <a-tag v-if="record.mapId === mapId" color="green">已绑定</a-tag>
          <a-button
            type="primary"
            v-else
            @click="handleSingleAdd(record.id)"
            shape="circle"
            icon="plus"
            size="small"
          ></a-button>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
// eslint-disable-next-line standard/object-curly-even-spacing
import { bindMapColumns, allOverlayapColumns, overlayTypeList } from './common/common'

export default {
  name: 'BindMap',
  data() {
    return {
      bindPagination: {
        current: 1,
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`,
      },
      overlayPagination: {
        current: 1,
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`,
      },
      mapId: 0,
      overlayIds: [],
      bindTableData: [],
      overlayTableData: [],
      bindSearchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        mapId: null,
        name: '',
        type: undefined,
      },
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        mapId: null,
        name: '',
        type: undefined,
        status: 'off',
      },
      bindColumns: bindMapColumns(),
      overlayColumns: allOverlayapColumns(),
      selectedRowKeys: [],
      OverlayTypeList: overlayTypeList(),
    }
  },
  created() {},
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    loadData(id) {
      this.mapId = id
      this.searchParams.mapId = id
      this.bindSearchParams.mapId = id
      this.overlaySearch()
      this.bindSearch()
    },
    overlaySearch() {
      this.$http(this, {
        url: SERVICE_URLS.overlay.search,
        noTips: true,
        data: this.searchParams,
        success: (data) => {
          this.overlayTableData = data.body.content
          this.overlayPagination.total = data.body.totalElements
          this.overlayPagination.current = data.body.number + 1
          this.overlayPagination.pageSize = data.body.size
          this.searchParams.number = data.body.number
        },
      })
    },
    bindSearch() {
      this.$http(this, {
        url: SERVICE_URLS.relation.search,
        noTips: true,
        data: this.bindSearchParams,
        success: (data) => {
          this.bindTableData = data.body.content
          this.bindPagination.total = data.body.totalElements
          this.bindPagination.current = data.body.number + 1
          this.bindPagination.pageSize = data.body.size
          this.bindSearchParams.number = data.body.number
        },
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.overlayIds = []
      this.selectedRowKeys = selectedRowKeys
      for (let item of selectedRows) {
        this.overlayIds.push(item.id)
      }
    },
    handleBindDelete(id, content) {
      const _this = this
      this.$confirm({
        title: '确认删除操作?',
        content: content || '此数据将会被删除',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          _this.$http(_this, {
            url: SERVICE_URLS.relation.delete,
            noTips: false,
            params: {
              id: id,
            },
            successTipsContent: '关联数据删除成功!',
            success: (data) => {
              _this.bindSearch()
              _this.overlaySearch()
            },
            error: (data) => {},
          })
        },
        onCancel() {},
      })
    },
    handleMultipleAdd() {
      this.$http(this, {
        url: SERVICE_URLS.relation.create_more,
        noTips: true,
        successTipsContent: '关联成功!',
        data: {
          mapId: this.mapId,
          overlayIds: this.overlayIds,
        },
        success: (data) => {
          this.bindSearch()
          this.overlaySearch()
        },
        error: (data) => {},
      })
    },
    handleSingleAdd(id) {
      this.overlayIds = []
      this.overlayIds.push(id)
      this.$http(this, {
        url: SERVICE_URLS.relation.create_more,
        noTips: true,
        successTipsContent: '关联成功!',
        data: {
          mapId: this.mapId,
          overlayIds: this.overlayIds,
        },
        success: (data) => {
          this.bindSearch()
          this.overlaySearch()
        },
        error: (data) => {},
      })
    },
    tableChange(pagination, filters, sorter) {
      this.searchParams.size = pagination.pageSize
      this.searchParams.number = pagination.current - 1
      if (sorter.order === 'ascend') {
        this.searchParams.direction = 'ASC'
      } else {
        this.searchParams.direction = 'DESC'
      }
      this.searchParams.order = sorter.field
      this.overlaySearch()
    },
    bindTableChange(pagination, filters, sorter) {
      this.bindSearchParams.size = pagination.pageSize
      this.bindSearchParams.number = pagination.current - 1
      if (sorter.order === 'ascend') {
        this.bindSearchParams.direction = 'ASC'
      } else {
        this.bindSearchParams.direction = 'DESC'
      }
      this.bindSearchParams.order = sorter.field
      this.bindSearch()
    },
  },
}
</script>
