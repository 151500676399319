<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">地图管理</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleAdd" icon="plus" type="primary">新增地图</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入名称" />
            </a-form-item>
            <a-form-item label="描述">
              <a-input v-model="searchParams.description" @change="search" placeholder="请输入描述" />
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleBind(record.id)" shape="circle" icon="deployment-unit" size="small"></a-button>
          <a-divider type="vertical" />
          <a-button @click="handleEdit(record.id)" shape="circle" icon="edit" size="small"></a-button>
          <a-divider type="vertical" />
          <a-button type="danger" @click="handleDelete(record.id)" shape="circle" icon="delete" size="small"></a-button>
        </template>
        <template slot="enableSlot" slot-scope="text, record">
          <a-tag :color="record.enable ? 'blue' : 'red'">{{ record.enable ? '启用' : '禁用' }}</a-tag>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      title="添加地图基本信息"
      :visible="addModal"
      :maskClosable="false"
      @ok="handleAddOk"
      @cancel="closeAddModal"
    >
      <Form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"> </Form>
    </a-modal>
    <!--修改Model-->
    <a-modal
      title="修改地图基本信息"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal"
    >
      <Form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"> </Form>
    </a-modal>
    <a-drawer
      title="覆盖物绑定"
      placement="right"
      width="700"
      :visible="overlyDrawer"
      @close="drawerClose"
      :closable="true"
    >
      <BindMap ref="bindMapRef"></BindMap>
    </a-drawer>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import entityCRUD from '../../common/mixins/entityCRUD'
import { amapColumns } from './common/common'
import PageLayout from '@/components/page/PageLayout'
import Form from './Form'
import BindMap from '../overlay/BindMap'

export default {
  name: 'List',
  mixins: [entityCRUD],
  components: { BindMap, PageLayout, Form },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.mapInfo,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        name: '',
        description: '',
        organizationId: null,
      },
      deptList: [],
      initColumns: amapColumns(),
      overlyDrawer: false,
      addLayerModal: false,
    }
  },
  created() {
    this.loadDept()
  },
  methods: {
    drawerClose() {
      this.overlyDrawer = false
    },
    loadDept() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.deptList = data.body
        },
      })
    },
    handleBind(id) {
      this.overlyDrawer = true
      this.$nextTick(() => {
        this.$refs['bindMapRef'].loadData(id)
      })
    },
  },
}
</script>
